import "core-js/modules/es.array.push.js";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "content title"
};
const _hoisted_2 = ["innerHTML"];
import { ref } from 'vue';
import { showNotify } from '@nutui/nutui';
import axios from 'axios';
export default {
  __name: 'SiliconflowList2',
  setup(__props, {
    expose: __expose
  }) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const title = ref('');
    const isListVisible = ref(false);
    // 显示弹出框
    const show = matchId => {
      isListVisible.value = true;
      getFixedBonusV1(matchId);
      // getRecommended(matchId);
    };
    const oddsHistory = ref({});
    const siliconflowList = ref([]);
    const getFixedBonusV1 = async matchId => {
      try {
        const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/football/getFixedBonusV1.qry?clientCode=3001&matchId=' + matchId);
        let data = response.data;
        if (data.success) {
          oddsHistory.value = data.value.oddsHistory;
          title.value = oddsHistory.value.homeTeamAbbName + ' VS ' + oddsHistory.value.awayTeamAbbName;
          const response2 = await axios.get('https://tuhaojie.asia/api-foot/siliconflow/getList?matchId=' + matchId);
          let data2 = response2.data;
          let list = [];
          for (let i = 0; i < data2.length; i++) {
            if (data2[i].status == 8 || data2[i].status == 9 || data2[i].status == 10 || data2[i].status == 11) {
              list.push(data2[i]);
            }
          }
          siliconflowList.value = list;
          console.log(siliconflowList.value);
        } else {
          timeNotify(data.errorMessage);
        }
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    };

    // 简单的Markdown解析函数
    // 简单的Markdown解析函数
    const renderMarkdown = text => {
      // 替换标题
      text = text.replace(/^### (.*)/gm, '<h3>$1</h3>');
      text = text.replace(/^#### (.*)/gm, '<h4>$1</h4>');
      text = text.replace(/^##### (.*)/gm, '<h5>$1</h5>');
      text = text.replace(/^###### (.*)/gm, '<h6>$1</h6>');

      // 替换加粗和斜体
      text = text.replace(/\*\*(.*?)\*\*/gm, '<strong>$1</strong>');
      text = text.replace(/\*(.*?)\*/gm, '<em>$1</em>');

      // 替换无序列表
      text = text.replace(/^\* (.*)/gm, '<li>$1</li>');
      text = text.replace(/<li>(.*?)<\/li>/gm, '<ul><li>$1</li></ul>');

      // 替换有序列表
      text = text.replace(/^(\d+)\. (.*)/gm, '<li>$2</li>');
      text = text.replace(/<li>(.*?)<\/li>/gm, '<ol><li>$1</li></ol>');

      // 替换代码块
      text = text.replace(/^```(.*)/gm, '<pre><code>$1</code></pre>');

      // 替换链接
      text = text.replace(/\[(.*?)\]\((.*?)\)/gm, '<a href="$2">$1</a>');

      // 替换表格
      text = text.replace(/^\|(.*)\|/gm, '<tr><td>$1</td></tr>');
      text = text.replace(/<tr><td>(.*?)<\/td><\/tr>/gm, '<table><tr><td>$1</td></tr></table>');

      // 替换Mermaid图表
      text = text.replace(/```mermaid(.*?)```/gm, '<div class="mermaid">$1</div>');

      // 替换特殊格式
      text = text.replace(/```diff(.*?)```/gm, '<pre><code class="diff">$1</code></pre>');
      text = text.replace(/```python(.*?)```/gm, '<pre><code class="python">$1</code></pre>');
      text = text.replace(/```javascript(.*?)```/gm, '<pre><code class="javascript">$1</code></pre>');
      return text;
    };
    // 关闭弹出框
    const close = () => {
      isListVisible.value = false;
    };

    // 暴露给外部使用的函数
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_nut_col = _resolveComponent("nut-col");
      const _component_nut_row = _resolveComponent("nut-row");
      const _component_nut_collapse_item = _resolveComponent("nut-collapse-item");
      const _component_nut_collapse = _resolveComponent("nut-collapse");
      const _component_nut_popup = _resolveComponent("nut-popup");
      return _openBlock(), _createBlock(_component_nut_popup, {
        visible: isListVisible.value,
        "onUpdate:visible": _cache[0] || (_cache[0] = $event => isListVisible.value = $event),
        closeable: "",
        style: {
          padding: '30px 10px',
          height: '85%',
          width: '90%',
          overflow: 'auto'
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_nut_row, null, {
          default: _withCtx(() => [_createVNode(_component_nut_col, {
            span: 24
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString(title.value), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_nut_collapse, null, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(siliconflowList.value, (siliconflow, index) => {
            return _openBlock(), _createBlock(_component_nut_collapse_item, {
              name: index,
              key: index
            }, {
              title: _withCtx(() => [_createTextVNode(_toDisplayString(siliconflow.createTime), 1)]),
              default: _withCtx(() => [_createElementVNode("div", {
                innerHTML: renderMarkdown(siliconflow.content),
                class: "markdown-content"
              }, null, 8, _hoisted_2)]),
              _: 2
            }, 1032, ["name"]);
          }), 128))]),
          _: 1
        })]),
        _: 1
      }, 8, ["visible"]);
    };
  }
};